import React, { useState } from "react";
import Layout from "../../../../components/Layout";
import Seo from "../../../../components/seo";
import bgImage from "../../../../images/aichapter/sita/Hero-banner-Sita.png";
import RetailBgImg from "../../../../images/aichapter/sita/Sita-retail.png";
import RetailSVG from "../../../../images/aichapter/sita/retail.svg";
import ManufacturingBgImg from "../../../../images/aichapter/sita/Sita-manufacturing.png";
import ManufacturingSVG from "../../../../images/aichapter/sita/manufacturing.svg";
import BankgBgImg from "../../../../images/aichapter/sita/sita-bank.png";
import BankSVG from "../../../../images/aichapter/sita/Banking.svg";
import HealthCareBgImg from "../../../../images/aichapter/sita/sita-health.png";
import HealthCareSVG from "../../../../images/aichapter/sita/Healthcare.svg";
import PublicSectorBgImg from "../../../../images/aichapter/sita/Sita-public-sector.png";
import PublicSectorSVG from "../../../../images/aichapter/sita/Public-Sector.svg";
import ECommerceBgImg from "../../../../images/aichapter/sita/Sita-ecommerce.png";
import ECommerceSVG from "../../../../images/aichapter/sita/E-commerce.svg";
import SupplyBgImg from "../../../../images/aichapter/sita/Sita-supply.png";
import SupplySVG from "../../../../images/aichapter/sita/supply-Logistics.svg";
import AppSitaVideo from "../../../../images/aichapter/videos/app-1-sita.mp4";
import FeaturedSitaImg from "../../../../images/aichapter/sita/SITA-FeaturedImg.svg";
import ogIMage from "../../../../images/aichapter/sita/OG-image_Sita.png";
import FeatureViduraOneSVG from "../../../../images/aichapter/feature-vidura-1.svg";
import FeatureViduraTwoSVG from "../../../../images/aichapter/feature-vidura-2.svg";
import FeatureViduraThreeSVG from "../../../../images/aichapter/feature-vidura-3.svg";
import FeatureViduraFourSVG from "../../../../images/aichapter/feature-vidura-4.svg";
import FeatureViduraFiveSVG from "../../../../images/aichapter/feature-vidura-5.svg";
import * as SitaRealTimeAnimation from "../../../../animations/sita-real-time-answers.json";
import * as SitaAutomatedAnimation from "../../../../animations/sita-automated-processing.json";
import * as SitaAccurateAnalysis from "../../../../animations/sita-accurate-analysis.json";
import { Link } from "gatsby";
// import { StaticImage } from "gatsby-plugin-image";
import FeaturesOfDemos from "../../../../components/FeaturesOfDemos";
import Lottie from "react-lottie";
import NavigateBlockV2 from "../../../../components/NavigateBlockV2";
import ExplainedDemoAI from "../../../../components/ExplainedDemoAI";
import { Helmet } from "react-helmet";
import CountIncrement from "../../../../components/CountIncrement";
import SitaChatbotWrapper from "../../../../components/SitaChatbotWrapper";

const APISITAPage = () => {
    const [showSitaChatbot, setShowSitaChatbot] = useState(false);
    const handleSitaChatbot = (e) => {
        e.preventDefault();
        setShowSitaChatbot(true);
    };
    const NavbarTheme = {
        color: "light",
        paddingTop: "0px",
    };
    const ListOfSitaFeatures = [
        {
            id: 1,
            imageSrc: FeatureViduraOneSVG,
            title: "AI-Driven Accuracy",
            description:
                "Sita uses advanced AI to interpret user questions and generate precise API queries, ensuring accurate and relevant responses.",
        },
        {
            id: 2,
            imageSrc: FeatureViduraTwoSVG,
            title: "Dynamic Query Generation",
            description:
                "Automatically create complex API queries tailored to your needs, reducing manual effort and potential errors.",
        },
        {
            id: 3,
            imageSrc: FeatureViduraThreeSVG,
            title: "Comprehensive API Support",
            description:
                "Connect with multiple APIs, including RESTful, SOAP, and custom APIs and handle various data formats for versatile and robust data management.",
        },
        {
            id: 4,
            imageSrc: FeatureViduraFourSVG,
            title: "Advanced Data Filtering",
            description:
                "Automatically process and filter large volumes of data to ensure you get the most relevant data exactly when you need it, thus saving time and resources.",
        },
        {
            id: 5,
            imageSrc: FeatureViduraFiveSVG,
            title: "Scalability and Flexibility",
            description:
                "Deploy Sita AI on-premises, in the cloud, or in a hybrid environment to suit your organizational needs.",
        },
    ];
    const ListOfExplainAI = [
        {
            id: 1,
            btnTitle: "Retail",
            svgImg: RetailSVG,
            title: "Enhanced Customer Insights",
            content:
                "Integrate CRM and sales data APIs with Sita to filter and analyze customer data in real time. This helps to identify key trends and personalize marketing efforts, leading to increase in sales.",
            image: RetailBgImg,
        },
        {
            id: 2,
            btnTitle: "Manufacturing",
            svgImg: ManufacturingSVG,
            title: "Operational Efficiency",
            content:
                "Integrate data from various equipment sensors and monitoring systems with Sita to filter and analyze operational data. This allows for predictive maintenance and early identification of potential failures, reducing downtime and maintenance costs.",
            image: ManufacturingBgImg,
        },
        {
            id: 3,
            btnTitle: "Banking",
            svgImg: BankSVG,
            title: "Financial Risk Management",
            content:
                "Integrate market data, transaction records, and customer profiles with Sita to filter and analyze financial data. This enables the identification of risky transactions and fraudulent activities in real time, enhancing risk management and reducing fraud losses.",
            image: BankgBgImg,
        },
        {
            id: 4,
            btnTitle: "Healthcare",
            svgImg: HealthCareSVG,
            title: "Personalized Healthcare",
            content:
                "Integrate patient records, treatment histories, and genomic data with Sita to filter and analyze patient information. This helps in identifying patterns and suggesting personalized treatment plans, improving patient outcomes and satisfaction.",
            image: HealthCareBgImg,
        },
        {
            id: 5,
            btnTitle: "Public Sector",
            svgImg: PublicSectorSVG,
            title: "Smart City Solutions",
            content:
                "Integrate city data sources such as traffic cameras, sensors, and public reports with Sita to filter and analyze urban data. This aids in identifying traffic congestion patterns and potential safety hazards, enhancing traffic management and public safety.",
            image: PublicSectorBgImg,
        },
        {
            id: 6,
            btnTitle: "E-commerce",
            svgImg: ECommerceSVG,
            title: "Customer Experience Engagement",
            content:
                "Integrate product, customer, and transaction APIs with Sita to filter and analyze user behavior data. This improves personalized product recommendations, increasing recommendation-driven sales and customer retention.",
            image: ECommerceBgImg,
        },
        {
            id: 7,
            btnTitle: "Logistics",
            svgImg: SupplySVG,
            title: "Supply Chain Optimization",
            content:
                "Integrate data from various supply chain systems and APIs with Sita to filter and analyze logistics data in real time. This helps in optimizing routes, predicting delays, and streamlining operations, reducing delivery times and operational costs.",
            image: SupplyBgImg,
        },
    ];
    return (
        <Layout NavbarTheme={NavbarTheme}>
            <Helmet>
                <script
                    async
                    src="https://d1fmfone96g0x2.cloudfront.net/tarka-chat-2.4.0.umd.js"
                ></script>
            </Helmet>
            <Seo
                title="Intelligent API Integration AI Solution Sita- Sedin"
                description="Transform data strategy with Sita AI API Integration solution. Seamlessly connect, filter, analyze data from APIs to get actionable insights & drive business growth."
                image={`https://sedintechnologies.com${ogIMage}`}
            />
            <section
                className="AI-common-herostyle vidura-ai"
                style={{
                    background: `url(${bgImage})`,
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center center",
                }}
            >
                <div className="container container--banner">
                    <div className="AI-common-herostyle__text">
                        <div className="AI-common-herostyle__text-breadcrumbs text-fw-normal gap-2x text-clr-primary text-clr-white">
                            <Link className="text-decor-none" to="">
                                AI Chapter
                            </Link>
                            /
                            <Link className="text-decor-none" to="/ai-chapter/solutions">
                                Solutions
                            </Link>
                            /
                            <Link className="text-decor-none" to="">
                                Sita
                            </Link>
                        </div>
                        <h1 className="text-h1 AI-common-herostyle__text-header text-fw-medium gap-2x text-clr-white">
                            Sita
                        </h1>
                        <p className="text-p1 AI-common-herostyle__text-description text-fw-regular gap-2x text-clr-white">
                            Unleash the Full Potential of Your Data
                        </p>
                        <div>
                            <Link className="sdn-cta-btn" to="/contact/">
                                Contact us
                            </Link>
                        </div>
                    </div>
                    <div className="ai-demos-stats">
                        <p className="text-p1 AI-common-herostyle__text-description text-fw-regular gap-2x text-clr-white">
                            Simple service integrations. Sita provides personalized & accurate
                            answers every time.
                        </p>
                        <div className="ai-demos-stats__grid">
                            <div className="ai-demos-stats__grid-item">
                                <h1 className="text-h1 AI-common-herostyle__text-description text-fw-medium gap-05x text-clr-white">
                                    {/* 60% */}
                                    <CountIncrement start={0} end={97} duration={3000} />
                                </h1>
                                <p className="text-p3 text-fw-regular text-clr-secondary">
                                    Accuracy in Personalized answer
                                </p>
                            </div>
                            <div className="ai-demos-stats__grid-item">
                                <h1 className="text-h1 AI-common-herostyle__text-description text-fw-medium gap-05x text-clr-white">
                                    <CountIncrement start={0} end={30} duration={1000} showPlus />
                                </h1>
                                <p className="text-p3 text-fw-regular text-clr-secondary">
                                    API integration supported
                                </p>
                            </div>
                            <div className="ai-demos-stats__grid-item">
                                <h1 className="text-h1 AI-common-herostyle__text-description text-fw-medium gap-05x text-clr-white">
                                    <CountIncrement start={0} end={70} duration={3500} />
                                </h1>
                                <p className="text-p3 text-fw-regular text-clr-secondary">
                                    Reduction in response time
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="leverage-genAI-content demos-content">
                <div className="container container--first">
                    <div className="leverage-genAI-content__text">
                        <div className="text-h1 text-fw-medium gap-3x">
                            Seamlessly Integrate, Filter, and Analyze Your Data to Drive Business
                            Insights
                        </div>
                        <p className="text-p2 text-fw-regular text-clr-secondary gap-1x">
                            Sita leverages API integrations to provide personalized answers,
                            ensuring your customers receive the precise information they need
                            swiftly and accurately. Powered by Langchain and versatile API adapters,
                            Sita filters through data to deliver tailored responses, streamlining
                            your customer support processes. Our solution is designed to help
                            organizations like yours maximize the value of their data with minimal
                            effort.
                        </p>
                        <p className="text-p2 text-fw-regular text-clr-secondary gap-2x">
                            Our solution works with various APIs to provide a cohesive and
                            integrated support experience, ensuring all your systems communicate
                            effectively. The API AI solution automates the retrieval and processing
                            of data, reducing the manual effort required by your support team and
                            allowing them to focus on more complex issues.
                        </p>
                    </div>
                </div>
            </section>
            {/* vidura explained  */}
            <section className="demo-explain-sec">
                <div className="container container--mid-v2">
                    <div className="leverage-genAI-content__text">
                        <div className="text-h1 text-fw-medium gap-4x explain-word">
                            Sita Expl <span className="AI-word">ai</span>ned
                        </div>
                    </div>
                    <div className="">
                        <ExplainedDemoAI ListOfExplainAI={ListOfExplainAI} />
                    </div>
                </div>
            </section>
            <section className="Demos-video-sec">
                <div className="container container--mid-v2">
                    <div className="leverage-genAI-content__text">
                        <div className="text-h1 text-fw-medium gap-4x">
                            Reimagine the future of Customer support
                        </div>
                        <div className="Demos-video-sec__demovideo">
                            <div className="ai-solutions-wrapper-list-item__restitems__inner-video">
                                <video
                                    loop
                                    autoPlay
                                    muted
                                    playsInline
                                    controlsList="nodownload"
                                    className="ai-video-sita gap-2x"
                                    // style={{ height: "80vh", width: "70%" }}
                                >
                                    <source src={AppSitaVideo} type="video/mp4" />
                                </video>
                            </div>
                            <div>
                                <button
                                    onClick={(e) => handleSitaChatbot(e)}
                                    target="no_refer"
                                    className="sdn-cta-btn"
                                >
                                    Try Sita Now
                                </button>
                                {showSitaChatbot && <SitaChatbotWrapper />}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* features of vidura  */}
            <section className="features-of-demos">
                <div className="container container--mid-v2">
                    <div className="features-of-demos__wrapper">
                        <div className="features-of-demos__grid">
                            <div className="features-of-demos__grid-item-left">
                                <div className="sticky-container">
                                    <h2 className="text-h1 text-fw-medium text-clr-primary gap-2x">
                                        Features of Sita
                                    </h2>
                                    <div className="feature-img">
                                        <img
                                            src={FeaturedSitaImg}
                                            alt="feature-img"
                                            loading="lazy"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="features-of-demos__grid-item-right-containerbox">
                                <FeaturesOfDemos featuresList={ListOfSitaFeatures} />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="why-demos-sec">
                <div className="container container--mid-v2">
                    <div className="text-h1 text-fw-medium gap-4x">Why Sita?</div>
                    <div className="why-demos-sec__grid">
                        <div className="why-demos-sec__grid-item">
                            <div className="animation">
                                <Lottie
                                    options={{
                                        loop: true,
                                        autoplay: true,
                                        animationData: SitaRealTimeAnimation,
                                        rendererSettings: {
                                            preserveAspectRatio: "xMidYMid slice",
                                        },
                                    }}
                                />
                            </div>
                            <div className="content">
                                <h3 className="text-h3 text-fw-medium text-clr-primary gap-1x">
                                    Real-Time Answers
                                </h3>
                                <p className="text-p3 text-fw-regular text-clr-secondary">
                                    Get real-time answers to your business questions, enabling
                                    faster decision-making.
                                </p>
                            </div>
                        </div>
                        <div className="why-demos-sec__grid-item">
                            <div className="animation">
                                <Lottie
                                    options={{
                                        loop: true,
                                        autoplay: true,
                                        animationData: SitaAutomatedAnimation,
                                        rendererSettings: {
                                            preserveAspectRatio: "xMidYMid slice",
                                        },
                                    }}
                                />
                            </div>
                            <div className="content">
                                <h3 className="text-h3 text-fw-medium text-clr-primary gap-1x">
                                    Automated Processing
                                </h3>
                                <p className="text-p3 text-fw-regular text-clr-secondary">
                                    Let Sita AI handle data processing and filtering, saving you
                                    time and resources.
                                </p>
                            </div>
                        </div>
                        <div className="why-demos-sec__grid-item">
                            <div className="animation">
                                <Lottie
                                    options={{
                                        loop: true,
                                        autoplay: true,
                                        animationData: SitaAccurateAnalysis,
                                        rendererSettings: {
                                            preserveAspectRatio: "xMidYMid slice",
                                        },
                                    }}
                                />
                            </div>
                            <div className="content">
                                <h3 className="text-h3 text-fw-medium text-clr-primary gap-1x">
                                    Accurate Analysis
                                </h3>
                                <p className="text-p3 text-fw-regular text-clr-secondary">
                                    Utilize cutting-edge AI algorithms to analyze filtered data and
                                    provide precise insights.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="navigate-cta">
                <div className="container container--last-v2">
                    <NavigateBlockV2
                        button="Contact us"
                        title="Ready to Transform Your Customer Support?"
                    />
                </div>
            </section>
        </Layout>
    );
};

export default APISITAPage;
